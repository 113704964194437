var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title float-left pr-1 mb-0"},[_vm._v(" Create Role ")])])],1)],1)],1),_c('b-card',[_c('validation-observer',{ref:"roleCreateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Role*","label-for":"h-role-role","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Role","vid":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-role-role","placeholder":"Role Name","state":(errors.length > 0 || _vm.roleValidation) ? false : null,"name":"role"},on:{"input":function($event){_vm.roleValidation == true ? _vm.roleValidation = false : null}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.roleValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.roleError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Modules","vid":"modules","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Modules*","label-for":"h-role-modules","label-cols-md":"3","state":(errors.length > 0 || _vm.moduleValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-address-modules","label":"title","options":_vm.moduleOptions,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.moduleValidation == true ? _vm.moduleValidation = false : null}},model:{value:(_vm.modules),callback:function ($$v) {_vm.modules=$$v},expression:"modules"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.moduleValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.moduleError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('b-form-group',{attrs:{"label":"Redirect URL","label-for":"h-role-redirectURL","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Redirect URL","vid":"redirectURL","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-role-redirectURL","placeholder":"/appointments/calendar","state":(errors.length > 0 || _vm.redirectURLValidation) ? false : null,"name":"redirectURL"},on:{"input":function($event){_vm.redirectURLValidation == true ? _vm.redirectURLValidation = false : null}},model:{value:(_vm.redirectURL),callback:function ($$v) {_vm.redirectURL=$$v},expression:"redirectURL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.redirectURLValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.redirectURLError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-9"}},[_c('b-form-group',{attrs:{"label":"Description*","label-for":"h-role-description","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"h-role-description","placeholder":"Description","rows":"3","state":(errors.length > 0 || _vm.descriptionValidation) ? false : null,"name":"description"},on:{"input":function($event){_vm.descriptionValidation == true ? _vm.descriptionValidation = false : null}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.descriptionValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.descriptionError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-9"}},[_c('validation-provider',{attrs:{"name":"Permissions","vid":"permissions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Permissions","label-cols-md":"2","state":(errors.length > 0 || _vm.permissionValidation) ? false : null}},_vm._l((_vm.permissionOptions),function(mainKey,index){return _c('div',{key:index},_vm._l((mainKey),function(options,secondIndex){return _c('div',{key:secondIndex,staticClass:"mt-50"},[_c('h5',[_vm._v(" "+_vm._s(secondIndex)+" ")]),_c('div',{staticClass:"demo-inline-spacing ml-3"},_vm._l((options),function(option,key){return _c('b-form-checkbox',{key:key,attrs:{"value":option._id,"name":"permissions","disabled":option.disable},on:{"change":function($event){return _vm.checkForDisability($event, index, secondIndex, key, option)}},model:{value:(_vm.permissions),callback:function ($$v) {_vm.permissions=$$v},expression:"permissions"}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)])}),0)}),0)]}}],null,true)})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'directory-roles-index' }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Role")])],1)],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }